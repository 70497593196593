import * as React from 'react'
import {graphql} from 'gatsby'
import Layout from '../Layout'
import Button from '@mui/material/Button';
import {useTranslation,Link} from "gatsby-plugin-react-i18next";

import Box from '@mui/material/Box';
import {handleLinkWrapClick} from "../tools/path";

const IndexPage = ({location }) => {
    const {t} = useTranslation();


    const showDemo = () => {
      alert(t('demo_tips'))
    }

    return (
        <Layout type="full" htmlClassName="index" pathname={location.pathname}>
            <Box sx={{maxWidth:1000,margin:"0 auto",color:"#fff",textAlign:"center"}}>
                <Box component='h1' sx={{fontSize:50,mt:{xs:5,sm:10},mb:1}}>MT Photos</Box>
                <Box component='h2' sx={{fontSize: 18, mb: 5}}>{t('desc')}</Box>
                <Box component="p" sx={{lineHeight: 1.8}}>
                    {t('p1')}
                    <br/>
                    {t('p2')}
                </Box>
                <Box sx={{mt: 8}}>
                    <Button  size="lg" color="white" variant="outlined" onClick={showDemo}> {t('demo')}</Button>
                    <Button sx={{ml: 5}} size="lg" color="white" variant="outlined" onClick={handleLinkWrapClick}>
                        <Link to="/docs/start/introduction"> {t('start')}</Link>
                    </Button>
                </Box>
            </Box>

        </Layout>
    )
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;